/* eslint-disable react/no-danger */
import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import { toSlug } from '../utils/slugify';

const BlogPage = ({ data }) => {
  const blogData = data.prismicBlogpost.data;
  const { images, title, text, lead } = blogData;
  const url = toSlug(title.text);

  return (
    <Layout
      seo={{
        title: title.text,
        description: text.text,
        ogImage: images.url,
        url: `/${url}`
      }}
    >
      <div className="container mt-20 mb-20 text-black p-2">
        <div className="md:flex">
          <div className="md:w-1/2">
            <h2 className="pt-10">{title.text}</h2>
            <p className="pt-10 max-w-md">{lead.text}</p>
          </div>
          <div className="md:w-1/2 items-center justify-center pt-10">
            <img
              className="mx-auto md:pt-56 lg:pt-12  xl:pr-20 md:px-2"
              src={images.url}
              alt="title"
            />
          </div>
        </div>
        <div
          className="mt-12 mb-20 max-w-lg mx-auto"
          dangerouslySetInnerHTML={{ __html: text.html }}
        />
      </div>
    </Layout>
  );
};

export default BlogPage;

export const query = graphql`
  query BlogPost($prismicId: ID) {
    prismicBlogpost(prismicId: { eq: $prismicId }) {
      data {
        images {
          url
        }
        text {
          html
        }
        title {
          text
        }
        lead {
          text
        }
      }
    }
  }
`;
